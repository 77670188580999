import _ from 'lodash'
import { getText } from '../../lang'
import { replaceWords } from '../../utils'
import {
  fetchFromUrlGETAsync,
  fetchFromUrlPATCHAsync,
  fetchFromUrlPOSTAsync,
  fetchFromUrlPUTAsync,
  fetchV2FromUrlPOSTAsync,
  fetchV2FromUrlGETAsync,
  fetchV2FromUrlDELETEAsync,
  fetchFromUrlDELETEAsync,
} from '../../utils/UrlHelper'
import { store } from '../index'
import {
  APPOINTMENT,
  ARCHIVE_STATUS,
  CONSENT_EDIT,
  OUTBOUND_VOICE_AI,
} from '../../views/messages/utils/constants'
import { pushTempUrlParamsForConversaction } from './meetActions'
import customerActions from './customerActions'
import { SET_RECEIVER } from './redux/reducers/messageReducer'

// const SET_RECEIVER = 'SET_RECEIVER'

// const initialState = {
//   receiver: {},
// }

const urlMessages = '/messages'
const urlMessagesFilter = '/messages/filter'
const urlMessageAssignUser = '/messages/assign'
const urlMessageUnassignUser = '/messages/unassign'
const urlMessagesEmail = '/messages/send/email'
const urlMessagesSchedule = '/messages/schedule'
const urlMessagesScheduleGet = '/messages/schedule/actions'
const urlMessagesReminder = '/conversation-reminders'
const urlMessageBubble = '/conversation-bubbles'
const urlMessagesVideo = '/videos'
const urlConversationSummary = '/conversation-summary'

// export function reducer(state = initialState, action) {
//   switch (action.type) {
//     case SET_RECEIVER:
//       return { ...state, receiver: action.payload }
//     default:
//       return state
//   }
// }

const fs = {
  getPageList: async (start, range, searchKeyword = '', paginationOptions = {}) => {
    const defaultOptions = [
      { key: 'page', value: start + 1 },
      { key: 'perPage', value: 20 },
    ]

    const urlParams = [...defaultOptions]

    if (paginationOptions.topConversationId) {
      urlParams.push({
        key: 'topConversationId',
        value: paginationOptions.topConversationId,
      })
    }

    Object.keys(paginationOptions).forEach((key) => {
      if (key !== 'topConversationId') {
        urlParams.push({ key, value: paginationOptions[key] })
      }
    })

    if (searchKeyword) {
      urlParams.push({ key: 'search', value: searchKeyword })
    }
    const result = await fetchFromUrlGETAsync(urlMessages, urlParams)
    const sendRes = {
      success: result.success,
      max: result.success ? result.data.total_items || result.data.total_count : 0,
      data: result.success ? result.data.conversations : [],
    }
    if (paginationOptions.topConversationId) {
      sendRes.data.is_found_top_conversation = result.data.is_found_top_conversation
    }
    return sendRes
  },
  send: async (phoneNumber, message, files = null, conv) => {
    const urlParams = [
      { key: 'media', value: files },
      { key: 'message', value: message },
    ]

    if (conv.isNew) {
      urlParams.push({ key: 'conversationId', value: conv._id })
      pushTempUrlParamsForConversaction(conv, urlParams)
    }
    const result = await fetchFromUrlPOSTAsync(
      urlMessages + '/' + phoneNumber,
      urlParams
    )
    return result
  },
  sendToMessenger: async (messengerId, message, files = null, conv) => {
    const urlParams = [{ key: 'message', value: message }]

    if (files && files.length) {
      urlParams.push({ key: 'media', value: files })
    }
    const result = await fetchFromUrlPOSTAsync(
      urlMessages + '/' + messengerId + '/messenger',
      urlParams
    )
    return result
  },
  sendViaEmail: async (data) => {
    const {
      email,
      subject,
      message,
      files = null,
      replyMessageId,
      receiver_id,
      conversation,
    } = data

    const urlParams = [
      { key: 'message', value: message },
      { key: 'email', value: email },
      { key: 'subject', value: subject },
      { key: 'replyMessageId', value: replyMessageId },
    ]
    if (conversation.isNew) {
      urlParams.push({ key: 'conversationId', value: conversation._id })
      pushTempUrlParamsForConversaction(conversation, urlParams, true, true)
    } else {
      urlParams.push({ key: 'receiver_id', value: receiver_id })
    }
    if (files && files.length) {
      urlParams.push({ key: 'media', value: files })
    }
    const result = await fetchFromUrlPOSTAsync(urlMessagesEmail, urlParams)
    return result
  },
  sendScheduleByPhone: async (
    convId,
    phoneNumber,
    message,
    files,
    scheduleAt,
    conversation
  ) => {
    const urlParams = [
      { key: 'message', value: message },
      { key: 'scheduleAt', value: scheduleAt },
      { key: 'conversationId', value: convId },
    ]
    if (files && files.length) {
      urlParams.push({ key: 'media', value: files })
    }
    if (conversation.isNew) {
      urlParams.push({ key: 'conversationId', value: conversation._id })
      pushTempUrlParamsForConversaction(conversation, urlParams, true, true)
    }
    const result = await fetchV2FromUrlPOSTAsync(
      urlMessagesSchedule + '/' + phoneNumber,
      urlParams
    )
    return result
  },
  sendScheduleByEmail: async (
    convId,
    email,
    subject,
    replyMessageId,
    message,
    files,
    scheduleAt,
    conversation
  ) => {
    const urlParams = [
      { key: 'message', value: message },
      { key: 'email', value: email },
      { key: 'subject', value: subject },
      { key: 'replyMessageId', value: replyMessageId },
      { key: 'scheduleAt', value: scheduleAt },
      { key: 'conversationId', value: convId },
    ]

    if (conversation.isNew) {
      urlParams.push({ key: 'conversationId', value: conversation._id })
      pushTempUrlParamsForConversaction(conversation, urlParams)
    }
    if (files && files.length) {
      urlParams.push({ key: 'media', value: files })
    }
    const result = await fetchV2FromUrlPOSTAsync(
      urlMessagesSchedule + '/send/email',
      urlParams
    )
    return result
  },
  sendScheduleByMessenger: async (convId, message, files, scheduleAt) => {
    const urlParams = [
      { key: 'message', value: message },
      { key: 'scheduleAt', value: scheduleAt },
    ]
    if (files && files.length) {
      urlParams.push({ key: 'media', value: files })
    }
    const result = await fetchV2FromUrlPOSTAsync(
      urlMessagesSchedule + '/' + convId + '/messenger',
      urlParams
    )
    return result
  },
  sendVideo: async (videoUploadId) => {
    const urlParams = [{ key: 'videoUploadId', value: videoUploadId }]
    const result = await fetchFromUrlPOSTAsync(
      urlMessagesVideo + '/' + videoUploadId + '/send-message',
      urlParams
    )
    return result
  },
  conversationReminders: async (convId, notifyAt, files) => {
    const urlParams = [
      { key: 'notifyAt', value: notifyAt },
      { key: 'conversationId', value: convId },
    ]
    if (files && files.length) {
      urlParams.push({ key: 'media', value: files })
    }
    const result = await fetchV2FromUrlPOSTAsync(
      urlMessagesReminder + '/generate',
      urlParams
    )
    return result
  },

  apply: async (phoneNumber) => {
    return fetchFromUrlGETAsync(urlMessages + '/' + phoneNumber, [])
  },

  filterByExtensionData: async (obj) => {
    const urlParams = []

    if (obj?.email) {
      urlParams.push({ key: 'email', value: obj.email })
    }

    if (obj?.phone) {
      urlParams.push({ key: 'phone', value: obj.phone })
    }

    if (obj?.firstName) {
      urlParams.push({ key: 'firstName', value: obj.firstName })
    }

    if (obj?.lastName) {
      urlParams.push({ key: 'lastName', value: obj.lastName })
    }

    return fetchFromUrlGETAsync(urlMessagesFilter, urlParams)
  },

  getConversationById: async (_id) => {
    return fetchFromUrlGETAsync(urlMessages + '/conversation/' + _id, [])
  },

  markRead: async (itemId) => {
    return fetchFromUrlPOSTAsync(urlMessages + '/' + itemId + '/read', [])
  },

  archive: async (itemId) => {
    return fetchFromUrlPOSTAsync(urlMessages + '/' + itemId + '/archive-add', [])
  },

  archiveAll: async (conversationsIds) => {
    const urlParams = [{ key: 'conversationsIds', value: conversationsIds }]
    return fetchFromUrlPOSTAsync(urlMessages + '/archive-add', urlParams)
  },

  unarchive: async (itemId) => {
    return fetchFromUrlPOSTAsync(urlMessages + '/' + itemId + '/archive-remove', [])
  },

  unarchiveAll: async (conversationsIds) => {
    const urlParams = [{ key: 'conversationsIds', value: conversationsIds }]
    return fetchFromUrlPOSTAsync(urlMessages + '/archive-remove', urlParams)
  },

  assignUser: async (itemId, userId) => {
    const urlParams = [
      { key: 'conversation_id', value: itemId },
      { key: 'user_id', value: userId },
    ]
    return fetchFromUrlPOSTAsync(urlMessageAssignUser, urlParams)
  },

  unassignUser: async (itemId, userId) => {
    const urlParams = [
      { key: 'conversation_id', value: itemId },
      { key: 'user_id', value: userId },
    ]
    return fetchFromUrlPOSTAsync(urlMessageUnassignUser, urlParams)
  },

  retrieveUsers: async (itemId) => {
    return fetchFromUrlGETAsync(urlMessages + '/' + itemId + '/retrieve-users', [])
  },

  getConversationMessagesList: async (start, range, conversation_id, conv) => {
    const statusColorToText = (color = '') => {
      switch (color) {
        case 'red':
          return 'Refused'
        case 'yellow':
          return 'Pending'
        case 'green':
          return 'Compliant'
        default:
          return color
      }
    }

    const urlParams = [
      { key: 'page', value: start + 1 },
      { key: 'perPage', value: range },
    ]

    const result = await fetchFromUrlGETAsync(
      urlMessages + '/' + conversation_id + '/list',
      urlParams
    )
    let conversationTypeofArchive

    let customerConsentEdit
    const consentEditInfo = (result.data && result.data.consentEdit) || {}

    if (result.success && !_.isEmpty(result?.data)) {
      customerActions.setManualConsentEdit(consentEditInfo.lastEditByUser || {})
      customerActions.setManualConsentEdit(consentEditInfo.lastEdit || {})
      if (!_.isEmpty(result?.data?.nextScheduledMessages)) {
        let list = []
        if (Array.isArray(result.data.nextScheduledMessages.dripSequence)) {
          list = list.concat(result.data.nextScheduledMessages.dripSequence)
        }
        if (result.data.nextScheduledMessages.superhuman) {
          list.push(result.data.nextScheduledMessages.superhuman)
        }
        if (result.data.nextScheduledMessages.followUp) {
          result.data.nextScheduledMessages.followUp.type =
            result.data.nextScheduledMessages.followUp.status
          list.push(result.data.nextScheduledMessages.followUp)
        }
        result.data.nextScheduledMessages = list
      } else {
        result.data.nextScheduledMessages = []
      }
    }

    if (result.data && result.data.archiveInfo) {
      conversationTypeofArchive = {}
      const {
        archiveDate = '',
        userWhoArchive = '',
        unarchiveDate = '',
        userWhoUnarchive = '',
      } = result.data.archiveInfo.data

      conversationTypeofArchive.type = ARCHIVE_STATUS
      conversationTypeofArchive.archiveType = result.data.archiveInfo.action_type
      conversationTypeofArchive._id = result.data.archiveInfo._id
      if (result.data.archiveInfo.action_type === 'archive') {
        conversationTypeofArchive.createdAt = archiveDate
        conversationTypeofArchive.fullName = userWhoArchive
      } else {
        conversationTypeofArchive.createdAt = unarchiveDate
        conversationTypeofArchive.fullName = userWhoUnarchive
      }
    }

    // let resultConsentHistory;
    // if (conv && conv.receiver && conv.receiver._id) {
    //     resultConsentHistory = await fs.getAllConsentHistory(conv.receiver._id);
    // }

    if (consentEditInfo.lastEdit) {
      customerConsentEdit = { ...consentEditInfo.lastEdit.data }

      let {
        data: { whoChangeConsent = '', previousStatus = '', newStatus = '' },
        createdAt,
        _id,
      } = consentEditInfo.lastEdit

      previousStatus = statusColorToText(previousStatus)
      newStatus = statusColorToText(newStatus)

      customerConsentEdit.type = CONSENT_EDIT
      customerConsentEdit.message = replaceWords(
        getText('MESSAGE_CONSENT_CHANGED'),
        {
          user: whoChangeConsent,
          previousStatus: previousStatus,
          newStatus: newStatus,
        }
      )
      customerConsentEdit.createdAt = createdAt
      customerConsentEdit._id = _id
    }

    let scheduledMessageList
    let resultSchedularMessage = await fs.getScheduledMessages(conversation_id)

    if (resultSchedularMessage && resultSchedularMessage.success) {
      scheduledMessageList = {}
      scheduledMessageList = resultSchedularMessage.data.find((item) => {
        return item.status === 'CREATED'
      })
    }

    let remindeMessageList
    let resultConversationReminder =
      await fs.getConversationReminders(conversation_id)

    if (resultConversationReminder && resultConversationReminder.success) {
      if (resultConversationReminder.data !== null) {
        remindeMessageList = {}
        remindeMessageList = resultConversationReminder.data
      }
    }

    let listData = []
    let resultRes = {
      success: result.success,
      data: result.success
        ? _.isEmpty(result.data)
          ? listData
          : listData
              .concat(result.data.messages)
              .concat(
                result.data.videos.map((inv) => {
                  inv.video = true
                  return inv
                })
              )
              .concat(
                result.data.appointments.map((inv) => {
                  inv.type = APPOINTMENT
                  return inv
                })
              )
              .concat(
                result.data.invitations.map((inv) => {
                  inv.isInvitation = true
                  return inv
                })
              )
              .concat(
                result.data.summaries.map((inv) => {
                  inv.isSummaries = true
                  return inv
                })
              )
              .concat(
                result.data.voice_ai_outgoing_calls.map((inv) => {
                  inv.type = OUTBOUND_VOICE_AI
                  return inv
                })
              )
              .concat(result.data.page > 1 ? [] : result.data.nextScheduledMessages)
        : // .concat(
          //   result.data.conversationBubbles.map((inv) => {
          //     inv.bubble = true;
          //     return inv;
          //   })
          // )
          [],
      max: result.success ? result.data?.total_items : 0,
      total_pages: result.success ? result.data?.total_pages : 0,
    }
    if (conversationTypeofArchive) {
      resultRes.data.push(conversationTypeofArchive)
    }

    if (customerConsentEdit) {
      resultRes.data.push(customerConsentEdit)
    }

    if (scheduledMessageList) {
      scheduledMessageList.isScheduled = true
      resultRes.data.push(scheduledMessageList)
    }

    if (remindeMessageList) {
      remindeMessageList.isReminded = true
      resultRes.data.push(remindeMessageList)
    }

    if (!_.isEmpty(result.data && result.data.conversationDraft)) {
      result.data.conversationDraft.isDraft = true
      resultRes.data.push(result.data.conversationDraft)
    }

    resultRes.data = resultRes.data.sort((msgA, msgB) => {
      if (msgA.createdAt > msgB.createdAt) return 1
      if (msgA.createdAt < msgB.createdAt) return -1
      return 0
    })
    return resultRes
  },

  sendTranscriptionCRM: async (idConversation, idLocation) => {
    const urlParams = [{ key: 'location_id', value: idLocation }]
    return fetchFromUrlPOSTAsync(
      urlMessages + '/' + idConversation + '/send-to-crm',
      urlParams
    )
  },

  getAllConsentHistory: async (idCustomer) => {
    if (idCustomer) {
      return fetchFromUrlGETAsync(`/history/consent/${idCustomer}`, [])
    }
    return { success: false }
  },

  addTagToConversation: async (idConversation, idTag) => {
    const urlParams = [{ key: 'tag_id', value: idTag }]
    return fetchFromUrlPUTAsync(
      urlMessages + '/' + idConversation + '/tags-add',
      urlParams
    )
  },
  removeTagToConversation: async (idConversation, idTag) => {
    const urlParams = [{ key: 'tag_id', value: idTag }]
    return fetchFromUrlPUTAsync(
      urlMessages + '/' + idConversation + '/tags-remove',
      urlParams
    )
  },
  sendConsentByPhone: async (phone, obj, conv) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    pushTempUrlParamsForConversaction(conv, urlParams)

    return fetchFromUrlPOSTAsync(
      urlMessages + '/' + phone + '/sendconsent',
      urlParams
    )
  },
  selectLocationForConversation: async (conId, _location_id) => {
    const urlParams = [{ key: '_location_id', value: _location_id }]
    return fetchFromUrlPATCHAsync(
      urlMessages + '/' + conId + '/location/attach',
      urlParams
    )
  },

  getEleadConversation: (eleadMessageToken) => {
    return fetchFromUrlGETAsync(
      `${urlMessages}/crm/conversation?hash=${eleadMessageToken}`
    )
  },

  getScheduledMessages: async (convId) => {
    let result = await fetchV2FromUrlGETAsync(
      `${urlMessagesScheduleGet}/conversation/${convId}?skip=0&limit=100`,
      []
    )
    return result
  },

  deleteScheduledMessage: async (id) => {
    const result = await fetchV2FromUrlDELETEAsync(
      urlMessagesScheduleGet + '/scheduled-message/' + id,
      []
    )
    return result
  },

  getConversationReminders: async (convId) => {
    let result = await fetchV2FromUrlGETAsync(
      `${urlMessagesReminder}/conversations/${convId}`,
      []
    )
    return result
  },

  deleteRemindedMessage: async (id) => {
    const result = await fetchV2FromUrlDELETEAsync(
      urlMessagesReminder + '/' + id,
      []
    )
    return result
  },

  updateBubble: async (bubbleId, status) => {
    const urlParams = [{ key: 'status', value: status }]
    const result = await fetchFromUrlPATCHAsync(
      `${urlMessageBubble}/${bubbleId}`,
      urlParams
    )
    return result
  },

  sendExtensionCRM: async (idConversation) => {
    return fetchFromUrlPOSTAsync(
      urlMessages + '/' + idConversation + '/crm-synchronize',
      []
    )
  },
  archiveAllConversation: async (exceptConversationsIds, paginationOptions) => {
    const urlParams = []

    Object.keys(paginationOptions).forEach((key) => {
      if (paginationOptions[key] !== null && paginationOptions[key] !== '') {
        urlParams.push({ key, value: paginationOptions[key] })
      }
    })

    if (exceptConversationsIds.length > 0) {
      urlParams.push({
        key: 'exceptConversationsIds',
        value: exceptConversationsIds,
      })
    }
    return fetchFromUrlPOSTAsync(urlMessages + '/archive-add-all', urlParams)
  },
  tagUsersInConversation: async (conId, message, tagList) => {
    const urlParams = [
      { key: 'message', value: message },
      { key: 'userIds', value: tagList },
    ]

    const result = await fetchFromUrlPOSTAsync(
      urlMessages + '/' + conId + '/tag-users',
      urlParams
    )
    return result
  },
  getConversationSummary: async (idConv) => {
    if (idConv) {
      return fetchFromUrlGETAsync(
        urlConversationSummary + `/conversation/${idConv}`,
        []
      )
    }
    return { success: false }
  },
  sendConversationSummary: async (sumId, userIds) => {
    const urlParams = [{ key: 'userIds', value: userIds }]
    return fetchFromUrlPOSTAsync(urlConversationSummary + '/' + sumId, urlParams)
  },
  getAIReplyMessage: async (convId) => {
    let result = await fetchFromUrlGETAsync(
      `${urlMessages}/${convId}/ai-reply`,
      [],
      180000
    )
    return result
  },
  saveDraft: async (obj, conversation_id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync(
      `${urlMessages}/${conversation_id}/draft`,
      urlParams
    )
    return result
  },
  setVoiceCall: async (phone, conversation) => {
    const urlParams = []
    if (conversation.isNew) {
      urlParams.push(
        { key: 'isNew', value: conversation.isNew },
        { key: 'conversationId', value: conversation._id }
      )
    }
    const result = await fetchFromUrlPOSTAsync(
      urlMessages + `/voice-call/${phone}`,
      urlParams
    )
    return result
  },
  unreadAllConversation: async (exceptConversationsIds, paginationOptions) => {
    const urlParams = []

    Object.keys(paginationOptions).forEach((key) => {
      if (paginationOptions[key] !== null && paginationOptions[key] !== '') {
        urlParams.push({ key, value: paginationOptions[key] })
      }
    })

    if (exceptConversationsIds.length > 0) {
      urlParams.push({
        key: 'exceptConversationsIds',
        value: exceptConversationsIds,
      })
    }
    return fetchFromUrlPOSTAsync(urlMessages + '/read-all', urlParams)
  },
  readAll: async (conversationsIds) => {
    const urlParams = [{ key: 'conversationsIds', value: conversationsIds }]
    return fetchFromUrlPOSTAsync(urlMessages + '/read', urlParams)
  },
  unArchiveAllConversation: async (exceptConversationsIds, paginationOptions) => {
    const urlParams = []

    Object.keys(paginationOptions).forEach((key) => {
      if (paginationOptions[key] !== null && paginationOptions[key] !== '') {
        urlParams.push({ key, value: paginationOptions[key] })
      }
    })

    if (exceptConversationsIds.length > 0) {
      urlParams.push({
        key: 'exceptConversationsIds',
        value: exceptConversationsIds,
      })
    }
    return fetchFromUrlPOSTAsync(urlMessages + '/archive-remove-all', urlParams)
  },

  skipScheduledMessages: async (convId, type, messageId) => {
    const urlParams = [
      { key: 'skippedMessageId', value: messageId },
      { key: 'skippedMessageType', value: type },
    ]
    const result = await fetchFromUrlDELETEAsync(
      urlMessages + '/' + convId + '/scheduled-message',
      urlParams
    )
    return result
  },
  messagePreviewDynamicTags: async (message, conversationId, subject) => {
    const urlParams = [
      { key: 'message', value: message },
      { key: 'subject', value: subject },
      { key: 'conversationId', value: conversationId },
    ]
    const result = await fetchFromUrlPOSTAsync(urlMessages + '/preview', urlParams)
    return result
  },
  snoozeSuperhumanAction: async (convId, snoozed) => {
    const urlParams = [{ key: 'snoozed', value: snoozed }]
    const result = await fetchFromUrlPATCHAsync(
      urlMessages + '/' + convId + '/superhuman',
      urlParams
    )
    return result
  },
}

const loc = {
  filterEmptyMessages: (obj) => {
    obj.messages = obj.messages.filter((message) => !!message.body)
    return obj
  },

  setReceiver: (receiver) => {
    store.dispatch({ type: SET_RECEIVER, payload: receiver })
  },

  getReceiverData: () => {
    return store.getState().messageReducer.receiver
  },
}

const messageActions = Object.assign(fs, loc)

export default messageActions
