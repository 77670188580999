import moment from 'moment-timezone'
import {
  fetchFromUrlGETAsync,
  fetchFromUrlPUTAsync,
  fetchFromUrlPATCHAsync,
  fetchFromUrlPOSTAsync,
  fetchFromUrlDELETEAsync,
} from '../../utils/UrlHelper'
import { store } from '../index'
import { pushTempUrlParamsForConversaction } from './meetActions'
import messageActions from './messageActions'
import authActions from './authActions'
import {
  SET_LAST_CONSENT_EDIT,
  SET_MANUAL_CONSENT_EDIT,
} from './redux/reducers/customerReducer'

const urlCustomers = '/customers'

// const SET_MANUAL_CONSENT_EDIT = 'SET_MANUAL_CONSENT_EDIT'
// const SET_LAST_CONSENT_EDIT = 'SET_LAST_CONSENT_EDIT'

// const initialState = {
//   manualConsentEdit: {},
//   lastConsentEdit: {},
// }

// export function reducer(state = initialState, action) {
//   switch (action.type) {
//     case SET_MANUAL_CONSENT_EDIT:
//       return { ...state, manualConsentEdit: action.payload }
//     case SET_LAST_CONSENT_EDIT:
//       return { ...state, lastConsentEdit: action.payload }
//     default:
//       return state
//   }
// }

const fs = {
  getSingle: async (itemId) => {
    const result = await fetchFromUrlGETAsync(urlCustomers + '/' + itemId, [])
    return result
  },

  saveUpdate: async (obj, itemId) => {
    // if (!obj.email) {
    //   delete obj.email
    // }
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPUTAsync(urlCustomers + '/' + itemId, urlParams)
    return result
  },

  changeLanguage: async (language, itemId) => {
    const urlParams = [{ key: 'language', value: language }]
    const result = await fetchFromUrlPATCHAsync(
      urlCustomers + '/' + itemId + '/language',
      urlParams
    )
    return result
  },

  sendCalendarInvitation: async (objx, location_id, conv) => {
    const organization = authActions.getOrganization()

    let obj = {
      event_start_date: moment(objx.date).tz(organization.timezone, true),
      createOutgoingVoiceAICall: objx.createOutgoingVoiceAICall,
      assistant_id: objx.assistant_id,
    }

    if (objx.messengerId) {
      obj.messengerId = objx.messengerId
    } else {
      if (objx.hasOwnProperty('customerId')) {
        obj.customerId = objx.customerId
      } else {
        obj.phone = objx.phone
      }
    }

    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    pushTempUrlParamsForConversaction(conv, urlParams)

    const url = urlCustomers + '/send-calendar-invitation/' + location_id

    const result = await fetchFromUrlPOSTAsync(url, urlParams)
    return result
  },

  deleteCalendarInvitation: async (appointment_id, customer_id, location_id) => {
    const urlParams = [{ key: 'appointment_id', value: appointment_id }]

    const url =
      urlCustomers + '/' + customer_id + '/send-calendar-invitation/' + location_id
    const result = await fetchFromUrlDELETEAsync(url, urlParams)
    return result
  },

  updateCalendarInvitation: async (objx, customer_id, location_id) => {
    const organization = authActions.getOrganization()

    let obj = {
      appointment_id: objx._id,
      event_start_date: moment(objx.date).tz(organization.timezone, true),
      createOutgoingVoiceAICall: objx.createOutgoingVoiceAICall,
      assistant_id: objx.assistant_id,
    }

    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const url =
      urlCustomers + '/' + customer_id + '/send-calendar-invitation/' + location_id
    const result = await fetchFromUrlPATCHAsync(url, urlParams)
    return result
  },

  setAutoReply: async (obj, itemId) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPATCHAsync(
      urlCustomers + '/' + itemId + '/auto-reply',
      urlParams
    )
    return result
  },

  sendConsent: async (itemId) => {
    const result = await fetchFromUrlPOSTAsync(
      urlCustomers + '/' + itemId + '/sendconsent',
      []
    )
    return result
  },

  changeConsentStatus: async (newStatus, customerId) => {
    const parameters = [{ key: 'status', value: newStatus }]
    const result = await fetchFromUrlPUTAsync(
      `${urlCustomers}/${customerId}/consent`,
      parameters
    )

    if (result.success) {
      messageActions.setReceiver(result.data)

      return result.data
    }
  },

  getVehicleInterest: async (itemId) => {
    const result = await fetchFromUrlGETAsync(
      urlCustomers + '/' + itemId + '/vehicles',
      []
    )
    return result
  },

  revertConsent: async (_customer_id) => {
    return await fetchFromUrlPUTAsync(
      urlCustomers + '/' + _customer_id + '/revert-consent',
      []
    )
  },

  setSuperhumanToggle: async (obj, customer_id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPUTAsync(
      urlCustomers + '/' + customer_id + '/superhuman-config',
      urlParams
    )
    return result
  },

  setFollowUpUpdate: async (obj, customer_id) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPUTAsync(
      urlCustomers + '/' + customer_id + '/follow-up-config',
      urlParams
    )
    return result
  },
}

const loc = {
  setManualConsentEdit: (value) => {
    store.dispatch({ type: SET_MANUAL_CONSENT_EDIT, payload: value })
  },

  setLastConsentEdit: (value) => {
    store.dispatch({ type: SET_LAST_CONSENT_EDIT, payload: value })
  },

  getManualConsentEdit: () => {
    return store.getState().customerReducer.manualConsentEdit
  },

  getLastConsentEdit: () => {
    return store.getState().customerReducer.lastConsentEdit
  },
}

const customerActions = Object.assign(fs, loc)

export default customerActions
