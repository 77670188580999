import React, { memo } from 'react'
import { Tooltip } from 'antd'
import SVGAppointmentDateIcon from '../../../../../icons/SVG/SVGAppointmentDateIcon'
import { getText } from '../../../../../lang'
import MatButton from '../../../../../components/MatButton'
import MatPopover from '../../../../../components/MatPopover'
import RenderBookingEdit from '../../../right/messageTabs/MessageTabBooking/RenderBookingEdit'
import { EMAIL } from '../../../../../devOptions'
import './MessageAppointment.scss'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'

const MessageAppointment = ({
  visibleAppointment,
  conversation,
  msgChangeEmailSMSType,
  setvisibleAppointment,
  isCustomerRedStatus,
  isCustomerYellowStatusAndRestricted,
  isCustomerPhoneNumberValid,
  isAwaitingConsent,
  mobileDeskSize,
  onCloseMobileDesk,
}) => {
  const { isMobile } = useDeviceCheck()

  if (!conversation.receiver) {
    return null
  }

  return conversation.kind === 'gbm' || isAwaitingConsent ? (
    <Tooltip
      title={
        isAwaitingConsent
          ? getText('TEXT_YOU_CANT_TEXT_CUSTOMERS_WHILE_AWAITING_CONSENT')
          : getText(
              'MSG_THIS_ACTION_IS_NOT_SUPPORTED_FOR_GOOGLE_BUSINESS_CONVERSATIONS_JUST_YET'
            )
      }
    >
      <div className='send-invitation-message icon-size disabled'>
        <span className='ant-btn-icon'>
          <SVGAppointmentDateIcon />
        </span>
        {mobileDeskSize ? <span>{getText('WORD_APPOINTMENTS')}</span> : ''}
      </div>
    </Tooltip>
  ) : (
    <MatPopover
      overlayClassName='add-appointment-popover-overlay'
      title={getText('WORD_APPOINTMENT_BOOKING')}
      destroyTooltipOnHide={true}
      visible={visibleAppointment}
      placement={
        (Boolean(conversation.receiver.email) &&
          Boolean(conversation.receiver.phone)) ||
        isMobile
          ? 'top'
          : 'topLeft'
      }
      onVisibleChange={() => {
        if (msgChangeEmailSMSType !== EMAIL) {
          setvisibleAppointment(!visibleAppointment)
        }
      }}
      content={
        <RenderBookingEdit
          item={null}
          conversation={conversation}
          dateDropdownPlacement={isMobile ? 'bottom-end' : 'bottom'}
          msgChangeEmailSMSType={msgChangeEmailSMSType}
          onCancel={() => {
            setvisibleAppointment(false)
          }}
          onRemove={() => setvisibleAppointment(false)}
          onCloseMobileDesk={onCloseMobileDesk}
          includeVoiceAIConfirmationBlock
        />
      }
      triggerPopover={
        <MatButton
          tooltip={!mobileDeskSize}
          tooltipTitle={getText('WORD_APPOINTMENT_TEXT')}
          id={'msg_appointment'}
          icon={<SVGAppointmentDateIcon />}
          onClick={() => {
            setvisibleAppointment(!visibleAppointment)
          }}
          buttonText={mobileDeskSize ? getText('WORD_APPOINTMENT_TEXT') : ''}
          disabled={
            isCustomerPhoneNumberValid ||
            isCustomerRedStatus ||
            isCustomerYellowStatusAndRestricted ||
            (conversation?.isNew && msgChangeEmailSMSType === EMAIL)
          }
          className='icon-size only-stroke footerAction'
          dataindex={'appointment'}
        />
      }
    />
  )
}

export default memo(MessageAppointment)
