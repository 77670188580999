import React from 'react'
import { Flex } from 'antd'
import { getText } from '../../lang'
import MyDefaultButton from '../MyDefaultButton/MyDefaultButton'

const VoiceAICallControls = ({
  callStatus,
  vogentAudioConn,
  connectAudio,
  muted,
}) => {
  // const [isPaused, setIsPaused] = useState(false);
  return (
    <Flex vertical align={'center'} gap={4}>
      {callStatus !== 'queued' && (
        <Flex gap={10}>
          {/* <MyDefaultButton
                        onClick={async () => {
                            await vogentCall.hangup();
                        }}
                        buttonText={'Hang Up'}
                    /> */}
          {/* <MyDefaultButton
                        onClick={() => {
                            vogentCall.setPaused(!isPaused).then(() => {
                                setIsPaused(!isPaused);
                            });
                        }}
                        buttonText={isPaused ? (
                            <span>Resume AI</span>
                        ) : (
                            <span>Pause AI</span>
                        )}
                    /> */}
          {vogentAudioConn && (
            <MyDefaultButton
              onClick={() => {
                vogentAudioConn.mute(!muted)
              }}
              buttonText={
                muted ? (
                  <span>{getText('WORD_UNMUTE')}</span>
                ) : (
                  <span>{getText('WORD_MUTE')}</span>
                )
              }
            />
          )}
          {!vogentAudioConn ? (
            <MyDefaultButton
              onClick={() => {
                connectAudio()
              }}
              buttonText={getText('WORD_JAMP_ON_CALL')}
            />
          ) : (
            <MyDefaultButton
              onClick={() => {
                vogentAudioConn.disconnect()
              }}
              buttonText={getText('WORD_DISCONNECT_AUDIO')}
            />
          )}
        </Flex>
      )}
    </Flex>
  )
}

export default VoiceAICallControls
