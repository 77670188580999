import { INBOUND } from '../../devOptions'
import {
  fetchFromUrlGETAsync,
  fetchFromUrlPATCHAsync,
  fetchFromUrlPOSTAsync,
} from '../../utils/UrlHelper'

const URL_VAPI_ASSISTANTS = '/vapi-assistants'
const URL_VOGENT_ASSISTANTS = '/vogent-assistants'
const URL_VOGENT_ASSISTANTS_LIST = '/vogent-assistants/list'
const URL_VOICE_AI_CALLS = '/voice-ai-calls'
const URL_MUX = '/mux/token'

const fs = {
  getVapiAssistants: async (objParams, type) => {
    const urlParams = [
      { key: '_organization_id', value: objParams.idOrganization },
      { key: 'page', value: Math.ceil(objParams.page + 1) },
      { key: 'perPage', value: objParams.perPage },
    ]
    if (objParams?.search) {
      urlParams.push({
        key: 'search',
        value: objParams.search,
      })
    }
    if (objParams.hasOwnProperty('enabled')) {
      urlParams.push({
        key: 'enabled',
        value: objParams.enabled,
      })
    }

    return await fetchFromUrlGETAsync(
      type === INBOUND ? URL_VAPI_ASSISTANTS : URL_VOGENT_ASSISTANTS,
      urlParams
    )
  },
  getAssistantsList: async (objParams) => {
    const urlParams = [{ key: '_organization_id', value: objParams.idOrganization }]
    if (objParams?.assistantType) {
      urlParams.push({
        key: 'assistantType',
        value: objParams.assistantType,
      })
    }

    return await fetchFromUrlGETAsync(URL_VOGENT_ASSISTANTS_LIST, urlParams)
  },
  getCallDetailsById: async (id) => {
    return await fetchFromUrlGETAsync(`${URL_VOICE_AI_CALLS}/${id}`)
  },
  updateVapiAssistant: async (obj, idAssistant, type) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })

    const result = await fetchFromUrlPATCHAsync(
      `${type === INBOUND ? URL_VAPI_ASSISTANTS : URL_VOGENT_ASSISTANTS}/${idAssistant}`,
      urlParams
    )
    return result
  },
  createVapiAssistant: async (obj, type) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPOSTAsync(
      type === INBOUND ? URL_VAPI_ASSISTANTS : URL_VOGENT_ASSISTANTS,
      urlParams
    )
  },

  getRecordingUrlByPlaybackId: async (idPlayBack) => {
    const urlParams = [{ key: 'playback_id', value: idPlayBack }]

    const result = await fetchFromUrlGETAsync(URL_MUX, urlParams)

    return result
  },
}

const loc = {}

const voiceAIActions = Object.assign(fs, loc)

export default voiceAIActions
